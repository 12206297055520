.nav {
	background: colorRed
	color: white
	text-transform: uppercase
	font-family: vag-rounded-bold
	font-size: 2rem
	font-family: headerFontFamily;
	line-height: 1
	height: 3.6em
	margin-top: 40px;
	@media (max-width: 767px) {
		margin-top: 20px
	}
	@media (max-width: 660px) {
		height: auto
		display: none
		font-size: 1.4rem
	}
}

// When header bar is fixed
.header-fixed .nav {
	margin-top: 20px;
	height: 60px;
	// border-bottom: 1px solid white;
}

body.nav-open  {
	position: fixed;
	overflow: hidden;

	header {
		padding-bottom: 0;
	}

	.modal {
		display: none;
	}
}

.nav--active {
	@media (max-width: 660px) {
		display: block
	}
}

.nav a {
	color: inherit
	text-decoration: none
}


.nav-main,
.nav-main ul {
	list-style: none
	margin: 0
	padding: 0
}

.nav-main {
	display: inline-block
	font-size: 0
	@media (max-width: 660px) {
		display: none !important
	}
}

.nav-main > li {
	font-size: 2rem
	text-align: center
	position: relative
	margin: 0 10px;

	&:hover {
		> a {
			background: colorGreyDark;
			+ .sub-menu {
				display: block;
			}
		}
	}

	@media(max-width:1400px) {
		margin: 0px;
	}

	&:first-of-type {
		margin-left: 0px;
	 }
}


.nav-main > li > a {
	height: 3.6em
	line-height: 3.6em
	display: block
	padding: 0 2em
	padding 0px 20px!important

	@media(max-width:1000px) {
		padding: 0px 15px!important;
	}
}

.header-fixed .nav-main > li > a {
	display: flex;
    align-items: center;
	height 60px;
    line-height: 0px;
}


.nav-main .sub-menu {
	display: none
	position: absolute
	z-index: 10
	top: 100%
	left: 0px;
	// left: 50%
	// transform: translate(-50%, 0)
	background: colorGreyDark
	font-size: 1.6rem
	font-weight: fontWeight
	min-width: 290px
	text-align: center
	padding: 5px 20px
	// margin-top: 20px;
	&:hover {
		display: block;
	}
	// &:before {
	// 	content: '';
	// 	display: block;
	// 	position: absolute;
	// 	bottom: 100%;
	// 	margin-top: -1px;
	// 	left: 0;
	// 	right: 0;
	// 	height: 21px;
	// }
	// &:after {
	// 	content: '';
	// 	display: block;
	// 	position: absolute;
	// 	bottom: 100%;
	// 	background: url('../images/sub-menu-arrow.svg') top left no-repeat;
	// 	background-position: center center;
	// 	width: 28px;
	// 	height: 20px;
	// 	left: 50%;
	// 	margin-left: -14px;
	// }
}

.nav-main .sub-menu > li {
	position: relative
	margin: 25px 0
	text-align: left;

	&:not(:first-child):before {
		content: ''
		display: block
		width: 100%
		height: 1px
		background: #a09f9f
		position: absolute
		top: -13px
		// left: 50%
		left: 0;
		// margin-left: -9px
	}
}


.nav-main .sub-menu a {
	&:hover {
		color: colorRed
	}
}




/**
 * Dropdown
 */
.nav__dropdown-toggle {
	appearance: none
	padding: 0
	background: none
	border: 0
	border-radius: 0
	color: white
	text-transform: uppercase
	padding: 0 2em
	@media (max-width: 660px) {
		display: none
	}
}

.priority-nav {
	display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-fixed .priority-nav {
	height 60px;
}

.priority-nav__dropdown {
	z-index: 10
	list-style: none
	margin: 0
	padding: 0
	background: colorRed
	text-align: center
	width: 320px
	right: 0
	border-top: 1px solid rgba(white, 25%)
}

.priority-nav__dropdown a {
	display: block
	padding: 15px 20px
}

.priority-nav__dropdown > li {
	border-bottom: 1px solid rgba(white, 25%)
}

.priority-nav__dropdown .sub-menu {
	list-style: none
	margin: 0
	padding: 0
	background: colorGreyDark
	font-size: 1.2rem
	font-weight: fontWeight
	display: flex
	flex-wrap: wrap

	li {
		flex: 0 0 50%
		border-top: 1px solid rgba(white, 25%)
		&:nth-child(odd) {
			border-right: 1px solid rgba(white, 25%)
		}
	}

	&:before,
	&:after {
		content: none;
	}
}


@media (max-width: 660px) {
	.nav__dropdown-wrapper {
		width: 100%
		display: block
	}
	.priority-nav__dropdown {
		width: auto
		margin-left: -20px
		margin-right: -20px
		position: static
		visibility: visible
	}
}

.nav-contact {

	@media(max-width: 660px) {
		display: none;
	}

	a {
		display: inline-block;
		font-family: bodyFontFamily;
		font-size: 1.9rem;
		text-transform: none;

		&:last-of-type {
			margin-left: 20px;
		}

		span {
			@media(max-width:1000px) {
				display: none;
			}
		}

		svg {
			max-width: 30px;
			max-height: 30px;
			fill: white;

			@media(min-width:1000px) {
				display: none;
			}
		}
	}
}


.sub-menu .current-menu-item {
	color: colorRed;
}