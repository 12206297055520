.cta--home .container {
    display: flex;
    justify-content: space-between;
    @media(max-width: 767px) {
       flex-direction: column;
    }
}

.cta--home a {
    display: inline-block;
    width: 32%;
    height: 300px;
    padding: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    text-decoration: none;
    text-align: center;
    transition: 0.4s;

    @media(max-width: 1300px) {
        height: 200px;
    }

    @media(max-width: 767px) {
       width: 100%;
    }

    &:nth-of-type(even) {
        @media(max-width: 767px) {
            margin: 20px 0px;
        }
    }
}

.cta--home a:hover {
    p {
        bottom: 30px;
    }
}

.cta--home p {
    position: absolute;
    width: 90%;
    max-width 200px;
    z-index: 2;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0px;
    font-size: 2.5rem;
    color: white;
    transition: 0.4s;
}


.section--intro p {
    margin: 0 auto;
    max-width: 650px;
    text-align: center;
    font-size: 2rem;
}