.header {
	position: fixed;
	top: 0;
	left: 0;
    width: 100%;
    z-index: 100;
	background: white
	padding-top: 40px
	padding-bottom: 0;

	// @media(min-width: 662px) {
	// 	transition 0.4s ease
	// }
	// @media(max-width 1280px) {
	// 	padding-top 0.5rem
	// 	padding-bottom 0.5rem
	// }

	@media (max-width: 767px) {
		padding-top: 0px;
	}
	@media (max-width: 660px) {
		padding-top: 2rem
		padding-bottom: 2rem
	}
}

// When header is stuck to top
.header-fixed .header {
    padding-top: 20px;
}


.header-inner {
	display: flex
	// align-items: flex-end
	align-items: center
	
	@media (max-width 660px) {
		padding-left: 5px
		padding-right: 5px
	}
}

.header__logo {
	flex: 0 1 335px
	transition: 0.4s ease;
	@media (max-width: 1150px) {
		flex: 0 1 210px
	}
	@media (max-width: 767px) {
		width: 250px
		position: absolute
		top: 42px
		left: 20px
	}
	@media (max-width: 660px) {
		width: 150px
		top: 26px;
		left: 20px;
	}
}

// When header is stuck to top
.header-fixed .header__logo {
	flex: 0 1 200px
	transition: 0.4s ease;

	@media (max-width: 1150px) {
		flex: 0 1 200px
	}
}

.header__logo img {
	display: block
	width: 100%
	height: auto
}


.header__main {
	display: flex
	flex-wrap: wrap
	flex: 1 1 auto
	margin-left: 4.5rem
	@media (max-width: 767px) {
		justify-content: flex-end
		align-items: center
		margin-left: 0
	}
	@media (max-width: 660px) {
		flex-wrap: nowrap
		border: 2px solid colorLightGrey
		margin-bottom: 0
	}
}


.header .social-icons {
	@media (max-width: 767px) {
		flex: 0 0 auto
		margin-top 35px
	}
	@media (max-width: 660px) {
		display: none
	}
}



.header-user {
	flex: 0 0 auto
	font-size: 0
	margin-left: 15px
	@media (max-width: 767px) {
		margin-bottom: 1.5rem
		margin-top 35px
	}
	@media (max-width: 660px) {
		margin: 0
		border-left: 2px solid colorLightGrey
		padding-left: 12px
	}
}

.header-user__link {
	display: inline-block
	vertical-align: top
	font-size: 3rem
	text-decoration: none
	width: 55px
	height: 55px
	border: 2px solid colorLightGrey
	text-align: center
	padding-top: 10px
	transition: 0.4s ease;
	&:not(:first-child) {
		margin-left: 15px
	}
	&:hover {
		@media (min-width: 661px) {
			background: colorGreyDark
			svg {
				fill: white
			}
			.basket-icon__count {
				border-color: colorRed
			}
		}
	}
	@media (max-width: 660px) {
		height: 41px
		border: 0
		padding: 10px 0 0
		width: auto
	}
}

// When header is stuck to top
.header-fixed .header-user__link {
	width: 45px;
	height: 45px;
	padding-top: 5px;
}

.header-user__link svg {
	width: 0.9em
	height: 0.9em
	fill: colorRed
	display: block
	margin: 0 auto

	@media(min-width: 660px) {
		position: relative;
		top: 2px;
	}
	@media (max-width: 660px) {
		width: 0.7em
		height: 0.7em
	}
}


.basket-icon {
	position: relative
	width: 1em
	margin-left: auto
	margin-right: auto
}

.basket-icon__count {
	position: absolute
	bottom: -6px
	right: -6px
	min-width: 20px
	height: 20px
	border-radius: 10px
	background: colorRed
	color: white
	font-size: 0.4em
	text-align: center
	line-height: 16px
	padding: 0 4px
	border: 2px solid white
}



.search-toggle {
	display: none
	@media (max-width: 660px) {
		display: inline-block
	}
}

.nav-toggle {
	display: none
	@media (max-width: 660px) {
		display: inline-block
		width: 42px
		height: 41px
		// background: colorRed
		border-left: 2px solid colorGreyDark
		margin-left: 12px
	}
}

.nav-toggle__icon {
	display: block
	width: 24px
	height: 19px
	margin: 11px 0 0 8px
	position: relative
	&:before,
	&:after {
		content: ''
		display: block
		position: absolute
		left: 0
		width: 100%
		height: 3px
		background: colorGreyDark
	}
	&:before {
		top: 0
	}
	&:after {
		bottom: 0
	}
	span {
		display: block
		position: absolute
		background: colorGreyDark
		width: 100%
		height: 3px
		top: 8px
	}
}

.nav-toggle--active .nav-toggle__icon {
	&:before {
		transform: translate(0, 8px) rotate(45deg)
	}
	&:after {
		transform: translate(0, -8px) rotate(-45deg)
	}
	span {
		opacity: 0
	}
}
