.p-b-0 {
	padding-bottom: 0 !important
}
.m-b {
	margin-bottom: vspace !important
}

.color-teal {
	color: colorRed
}
.color-red {
	color: colorBlue
}
.color-grey {
	color: colorGreyDark !important
}

.text-plain {
	text-decoration: none !important
}

.sr-only {
	position: absolute
	width: 1px
	height: 1px
	padding: 0
	margin: -1px
	overflow: hidden
	clip: rect(0, 0, 0, 0)
	border: 0
}

.section {
	padding-top: 50px
	padding-bottom: (50px - vspace)
}

.section--even-padding {
	padding-bottom: 50px;
}

.sectionn-pb0 {
	padding-bottom: 0px;
}

.section--main {
	background: white
	box-shadow: 0px 0px 20px 0px rgba(black, 0.2)
}

.section--white {
	background: white
}

.section--teal {
	background: colorRed
	color: white
}

.section--grey {
	background: colorGreyDark
	color: white
}

.col--text {
	width: 50%
	> *:first-child {
		margin-top: 0 !important
	}
}

/*------------------------------*\
	Type
\*------------------------------*/
.h1 {
	font-family: headerFontFamily;
	font-size: 2.8125em
	line-height: 1.1
	margin: 1em 0
	@media (max-width: 767px) {
		font-size: 2em;
	}
}

.h2,
.woocommerce h2 {
	font-family: headerFontFamily;
	font-size: 1.375em
	line-height: 1.1
	margin: 2em 0 0.5em
}

.h2--border {
	border-bottom: 1px solid colorRed
	padding-bottom: 8px
}

.h3,
.woocommerce h3 {
	font-family: headerFontFamily;
	font-size: 1.125em
	line-height: 1.1
	margin: 2em 0 0.5em
}







/**
 * Woocommerce Columns
 */

.col2-set {
	font-size: 0
	margin-left: -20px
	margin-right: -20px
}

.col2-set .col-1,
.col2-set .col-2 {
	font-size: fontSize
	display: inline-block
	width: 50%
	vertical-align: top
	padding-left: 20px
	padding-right: 20px
	@media (max-width: 767px) {
		width: 100%
	}
}

.col2-set .col-1 > :first-child {
	margin-top: 0 !important
}

@media (min-width: 768px) {
	.col2-set .col-2 > :first-child {
		margin-top: 0 !important
	}
}


form.login,
form.register,
form.lost_reset_password {
	max-width: 500px
}


/**
 * Page Header
 */
.page-header {
	display: flex
	align-items: center
	padding-top: 45px;
	padding-bottom: 45px;
	flex-direction: column;
	@media (max-width: 1000px) {
		flex-wrap: wrap;
	}
	@media (max-width: 767px) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

.page-header h1 {
	flex: 0 0 auto;
	margin: 0;
	margin-right: auto;
	@media (max-width: 1000px) {
		flex: 0 0 100%;
	}
}

.page-header__actions {
	flex: 0 1 auto;
	@media (max-width: 1000px) {
		flex: 0 0 100%;
		margin-top: 15px;
	}
}

.page-header__actions a {
	vertical-align: middle
	@media (max-width: 1000px) {
		margin-top: 10px;
	}
}

.page-header__actions a:not(:last-child) {
	margin-right: 35px;
}

// Home page about
.about {
	padding-top: 70px
	padding-bottom: 70px;

	@media(max-width: 568px) {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}

.about .h2 {
	margin-bottom: 3.5rem!important
}

.about .col-1 p:last-of-type {
	@media(min-width: 768px) {
		margin-bottom: 0px;
	}
}

.about .col-2 p:last-of-type {
	margin-bottom: 0px;
}


// Cookies popup
.cookies-popup {
	position:fixed;
	z-index:101;
	width:100%;
	left:0;
	top:-110px;
	overflow:hidden;
	transition:0.4s ease;
	background-color:#ffffff;
	border-bottom:2px solid colorRed;

	@media(max-width:568px) {
		top: -120px;
	}
}
.cookies-popup--active {
	top:0px;
	max-height:200px;
}
.cookies-popup__inner {
	width: auto;
	margin-left:auto;
	margin-right:auto;
	text-align:center;
}
.cookies-popup p {
	font-size:14px;
	padding:25px 15px;
	margin-bottom: 0px;
	color:colorGreyDark;
}
.cookies-popup a {
	color:colorRed;
	text-decoration:none;
	font-size:14px;
	margin-left:5px;

	&:first-of-type {
		margin-right: 3px;
	}
}
.cookies-popup a:hover {
	text-decoration:underline;
}
.cookies-popup span {
	color:#d02121;
	margin-left: 15px;
}

// Login prompt banner
.login-prompt-banner {
	position: fixed;
	right: 0px;
	bottom: 0px;
	z-index: 99;
	width: auto;
	background-color: #00aeef;
	color: white;
	text-align: center;
	-webkit-box-shadow: -2px -2px 5px 0px rgba(105,105,105,0.5);
	-moz-box-shadow: -2px -2px 5px 0px rgba(105,105,105,0.5);
	box-shadow: -2px -2px 5px 0px rgba(105,105,105,0.5);

	@media(max-width: 767px) {
		width: 100%;
	}

	a {
		text-decoration: none;
		display block
		color: white;
		margin: 0px;
		padding: 20px 25px
		transition: 0.4s ease;

		@media(max-width: 767px) {
			padding: 15px 20px;
		}

		&:hover {
			padding-bottom: 25px;
		}
	}

}

// Modal to prompt login
.modal-login-warning {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.8);
    padding: 20px;
	display: none
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.modal-login-warning--active {
	display: -ms-flexbox;
    display: flex;
}

.modal-login-warning .modal {
	text-align: center;
	max-width: 600px;
}

.modal-login-warning p {
	margin-top: 0px;
}

.modal-login-warning a {
	background: colorRed;
    color: #fff;
    height: 3.1579em;
    padding: 0.6em 1.2em;
    font-size: 1.9rem;
    line-height: 3.2em;
	text-decoration: none;
}

// 404
.fourohfour a {
	transition: 0.4s
}

.fourohfour a:hover {
	color: colorText
}


.logo-bg {
	background-image: url('../images/logo-bg.png');
	background-repeat: repeat;
	background-size: contain;
}


.gradient-bg {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 1;

		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); 
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); 
	}
}
