.search-box {
	flex: 1 1 auto
	position: relative
	color: white
	@media (max-width: 767px) {
		order: 2
		flex: 0 0 100%
		margin-top: 10px
	}
	@media (max-width: 660px) {
		display: none
		position: absolute;
		top: 85px;
		left: 0;
		right: 0;
		margin: 0;
	}
}

.search-box--active {
	@media (max-width: 660px) {
		display: block;
	}
}
.all--search {
	@media (max-width: 660px) {
		margin-top: 55px;
	}
}

.search-box__input {
	appearance: none
	width: 100%
	max-width: 450px;
	float: right;
	border: 2px solid transparent
	border-radius: 0
	border: 2px solid colorLightGrey
	height: 55px
	padding: 0 7.5rem 0 1.5rem
	color: inherit
	color: colorRed;
	font-weight: bodyFamilyFont;
	font-size: 1.8rem
	transition: 0.4s ease;
	&:focus {
		outline: none
		border-color: colorRed
	}

	@media(max-width: 767px)  {
		max-width: 100%;
	}
}

// When header is stuck to top
.header-fixed .search-box__input {
	height: 45px;
}

// .search-box__label {
// 	position: absolute
// 	top: 0
// 	left: 0
// 	line-height: 5.5rem
// 	bottom: 0
// 	padding-left: 17px
// 	cursor: text
// 	color: colorRed
// }


.search-box input:-ms-input-placeholder {
	  color: colorRed;
}
.search-box input::placeholder {
  color: colorRed;
}


.search-box__label--hidden {
	display: none
}

.search-box__submit {
	position: absolute
	top: 12px
	right: 0
	bottom: 10px
	appearance: none
	padding: 0
	border: 0
	border-radius: 0
	background: none
	width: 6rem
}

.search-box__submit svg {
	width: 2rem
	height: 2rem
	fill: colorRed
	display: block
	margin: auto
}
