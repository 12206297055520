/**
 * Order Review
 */
.woocommerce-checkout-review-order {
	max-width: 660px
}

.woocommerce-checkout-review-order-table {
	table-layout: fixed
	width: 100%
	text-align: left
	border-collapse: collapse

	thead th {
		border-bottom: 1px solid colorRed
		padding-bottom: 10px
	}

	tfoot tr:first-child {
		td, th {
			padding-top: 20px
		}
	}

	.cart_item th,
	.cart_item td {
		vertical-align: top;
	}
}

#order_review_heading {
	@extends .h2
}



.shop_table.my_account_orders {

	table-layout: fixed
	width: 100%
	border-collapse: collapse
	text-align: left
	margin-bottom: vspace

	@media (min-width: 951px) {

		th {
			padding-bottom: 10px
			border-bottom: 1px solid colorRed
		}

		td {
			padding-top: 20px
			padding-bottom: 20px
			border-bottom: 1px solid rgba(#bdbdbd, 30%)
			vertical-align: top
		}

		.order-number,
		.order-date {
			width: 190px
		}

		.order-total {
			text-align: right
			width: 150px
		}

		th.order-status,
		td.order-status {
			width: 190px
			text-align: right
		}

	}

	@media (max-width: 950px) {
		display: block;
		border-top: 1px solid rgba(#bdbdbd, 30%);
		thead,tbody,tr,th,td {
			display: block;
			margin: 0;
		}
		thead {
			display: none;
		}
		tr {
			border-bottom: 1px solid rgba(#bdbdbd, 30%);
			padding: 20px 0;
		}
		td[data-title]:not(.order-status):before {
			content: attr(data-title) ": ";
			font-weight: bold;
		}
	}

}




div.order-status {
	display: inline-block
	width: 100%
	max-width: 120px
	font-size: 1.2rem
	text-transform: uppercase
}

div.order-status svg {
	width: 100%
	height: 20px
	fill: colorRed
	@media (max-width: 950px) {
		display: none;
	}
}

.order-status__label {
	background: #f2f2f2
	color: colorRed
	border-radius: 4px
	text-align: center
	padding: 2px 10px
	margin-top: 5px
}



.order-items {
	list-style: none
	margin: 0
	padding: 0
}

.order-items li {
	position: relative
	padding-left: 14px
}

.order-items li:before {
	content: '- '
	position: absolute
	top: 0
	left: 0
}


.order-info mark {
	background: none;
	color: inherit;
	font-weight: bold;
}
