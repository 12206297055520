@font-face {
	font-family: 'vag-rounded-bold';
	src: url('../fonts/vag_rounded_bold.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/vag_rounded_bold.woff') format('woff'),
		 url('../fonts/vag_rounded_bold.woff2') format('woff2'),
		 url('../fonts/vag_rounded_bold.ttf') format('truetype'),
		 url('../fonts/vag_rounded_bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'vag-rounded-light';
	src: url('../fonts/vag_rounded_light.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/vag_rounded_light.woff') format('woff'),
		 url('../fonts/vag_rounded_light.woff2') format('woff2'),
		 url('../fonts/vag_rounded_light.ttf') format('truetype'),
		 url('../fonts/vag_rounded_light.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
