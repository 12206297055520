.user-info {
	font-size: 0
}

.user-info__col {
	width: 100%
	max-width: 350px
	font-size: fontSize
	display: inline-block
	vertical-align: top
	margin-right: 70px
}

.user-info h3 {
	font-size: 1.6rem
	margin: 2em 0 1em
}

.user-info__field {
	position: relative
}

.user-info__value {
	border-bottom: 1px solid rgba(#bdbdbd, 30%)
	padding-bottom: 8px
}

.user-info__edit {
	position: absolute
	right: 0
	bottom: 8px
}

.user-info__edit svg {
	width: 16px
	height: 16px
	fill: colorRed
}
