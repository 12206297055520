.btn,
.button,
.comment-respond .submit {
	display: inline-block
	vertical-align: top
	appearance: none
	background: none
	border-radius: 0
	border: 0
	text-decoration: none
	font-family: headerFontFamily;
	text-transform: uppercase
	line-height: 1
	padding: 0
}

.btn--primary,
.checkout-button,
input[type="submit"][name="login"],
input[type="submit"][name="register"],
input[type="submit"][name="save_account_details"],
input[type="submit"][name="save_address"],
input[type="submit"][name="woocommerce_checkout_place_order"] {
	background: colorRed
	color: white
	height: 3.1579em
	padding: 0 1.2em
	font-size: 1.9rem
	line-height: 3.2em
	&:hover {
		background: lighten(colorRed, 5%)
	}
	button& {
		line-height: normal
	}
}

.btn--secondary,
[name="update_cart"],
form.lost_reset_password input[type="submit"],
.comment-respond .submit {
	background: colorGreyDark
	color: white
	font-size: 1.6rem
	height: 2.5em
	padding: 0 1.2em
	&:hover {
		background: lighten(colorGreyDark, 7%)
	}
}

.btn--secondary:not(button) {
	line-height: 2.5em
}

.btn--text,
[name="apply_coupon"] {
	color: colorRed
	text-decoration: underline
	font-size: 1.6rem
}
