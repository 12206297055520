.brand-slider-container {
	position: relative
	margin: 30px 0 vspace
}

.brand-slider {
	font-size: 0
	padding: 0 30px
}
.brand-slider__item {
	width: 15%
	padding 0px 20px
	font-size: fontSize
	display: inline-block
	vertical-align: middle
	text-align: center
	height: 100px
	display: flex
	align-items: center
	@media (max-width: 991px) {
		width: 25%
	}
	@media (max-width: 767px) {
		width: 33.3333333%
	}
	@media (max-width: 550px) {
		width: 50%
	}
}

.brand-slider a {
	display: block
	width: 100%
}

.brand-slider img {
	width: 90%
	height: auto
	max-height: 100px
	display: block
	margin: 0 auto
}


.brand-slider__nav {
	position: absolute
	top: 50%
	margin-top: -14px
	appearance: none
	background: none
	border: 0
	border-radius: 0
	padding: 0
	display: block
	width: 28px
	height: 28px
	@media (max-width: 550px) {
		width: 20px
		height: 20px
		margin-top: -10px
	}
}

.brand-slider__nav--prev {
	left: 15px
}

.brand-slider__nav--next {
	right: 15px
}

.brand-slider__nav svg {
	width: 28px
	height: 28px
	fill: colorRed
	@media (max-width: 550px) {
		width: 20px
		height: 20px
	}
}
