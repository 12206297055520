.product-inner {
	display: flex
	align-items: flex-start
	max-width: 1440px;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: auto;
	margin-right: auto;
	@media (max-width: 900px) {
		flex-wrap: wrap;
	}
}

.modal .product-inner {
	padding: 0;
}

.product .product-inner {
	padding-bottom: 50px;
}

.product-details {
	flex: 0 1 470px
	margin-right: 50px
	font-size: fontSize;
	@media (max-width: 900px) {
		flex: 0 0 100%
		margin-right: 0
	}
}


.product-title {
	display: flex
}
.product-details .h1 {
	margin: 0
	flex: 1 1 auto
}
.product-title__rating {
	flex: 0 0 auto
	line-height: 1
	margin-left: 20px
	text-align: right;
}

.product-details .onsale {
	display: none;
}

.product-details .price strong {
	color: colorRed
	font-size: 5rem
	font-family: headerFontFamily;
}
.product-details .price ins {
	text-decoration: none;
}

.product__rrp {
	text-decoration: line-through
}

.product__description {
	background: white
	color: #b3b3b3
	padding: 30px
	box-shadow: 0 0 5px rgba(black, 0.3)
	margin-bottom: vspace

	ul {
		padding-left: 17px
	}

	.modal & {
		padding: 0
		box-shadow: none
	}
}

.product-details .quantity {
	margin-bottom: vspace
}
.product-details .quantity__label {
	width: 120px;
	display: inline-block;
}
.product-details .qty {
	width: 70px
}


.product-images {
	flex: 1 1 500px
	position: relative
	font-size: 1.6rem

	@media (max-width: 900px) {
		order: -1
		margin-bottom: vspace
	}
}

.product-image {
	// width: auto
	width: 100%
	max-height 1000px
	margin-left auto
	margin-right auto
	display: block
	box-shadow: 0 0 5px rgba(black, 0.3)
}

.product-images__nav {
	display: none
	position: absolute
	z-index: 1
	bottom: 0
	background: colorRed
	width: 2em
	height: 2em
	appearance: none
	padding: 0
	border: 0
	border-radius: 0
	&:hover {
		background: colorGreyDark
	}
	.flickity-enabled & {
		display: block
	}
}

.product-images__nav--prev {
	right: 2em
	@media (max-width: 900px) {
		right: auto
		left: 0
	}
}

.product-images__nav--next {
	right: 0
}

.product-images__nav svg {
	fill: white
	width: 1em
	height: 1em
	display: block
	margin: auto
}



.single_add_to_cart_button {
	@extends .btn
	@extends .btn--primary
}



.reset_variations {
	display: none !important;
}



.related.products {
	@extends .container;
	padding-top: 50px;
	padding-bottom: 25px;
	h2 {
		margin-top: 0;
	}
}



.variations {
	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;
	th, td {
		padding: 0 0 20px;
	}
	.label {
		width: 120px;
		@media (max-width: 600px) {
			padding-bottom: 5px !important;
		}
	}
	@media (max-width: 600px) {
		display: block;
		thead,tbody,tr,th,td {
			display: block;
		}
	}
}


.product-details .product_meta {
	display: none;
}


.product-actions {
	font-size: 0;
	margin-top: 25px;
}
.product-actions__label {
	font-size: fontSize;
	display: block;
	margin-bottom: 5px;
}
.product-actions a {
	display: inline-block;
	height: 35px;
	line-height: 35px;
	background: white;
	padding: 0 12px;
	font-size: 15px;
	text-decoration: none;
	color: inherit;
	box-shadow: 0 0 5px rgba(black, 0.3);
	margin-right: 12px;
}
.product-actions svg {
	width: 16px;
	height: 16px;
	margin-right: 5px;
	vertical-align: -0.1em;
}
.product-action--facebook svg {
	fill: #3b5998;
}
.product-action--twitter svg {
	fill: #55acee;
	margin-right: 8px;
}



.single_add_to_cart_button[disabled] {
	opacity: 0.5;
}


.nj-added {
	display: none;
	color: colorRed;
	margin-top: 12px;
}
.nj-added--visible {
	display: block;
}
