html {
	font-size: 62.5%
}

body {
	padding-top: 223px;
	// background: url('../images/bg.svg') top left repeat, url('../images/star.svg') -9999px -9999px no-repeat;
	background-color: colorBg;
	color: colorText
	font-family: bodyFontFamily
	font-size: fontSize
	line-height: lineHeight

	@media (max-width: 1150px) {
		padding-top: 207px;
	}

	@media(max-width: 660px) {
		padding-top: 85px;
	}
}

body.header-fixed {
	padding-top: 145px;
}

img {
	max-width: 100%
}

p,
address {
	margin: 0 0 vspace
}

a {
	color: colorRed
	text-decoration: underline
}
a[disabled] {
	cursor: default
}

b,
strong {
	font-family: headerFontFamily
}


input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
textarea {
	appearance: none
	background: white
	border: 2px solid #cecaca
	border-radius: 0
	height: 4.5rem
	padding: 0 1.2rem
	width: 100%
	&:focus {
		outline: none
		border-color: colorRed
	}
}

textarea {
	padding: 1.2rem
	height: 139px
	resize: vertical;
}



.contact {
	@media(min-width: 1000px) {
		display: flex
		align-items: flex-start
		flex-direction: row
	}
	 
	&__col {
		font-size: 2rem
		margin-bottom: 60px;
	
		@media(min-width: 1000px) {
			width: 50%;
		}

		h2 {
			margin-bottom 0
		}

		input[type="submit"] { 
			background-color #ffffff
			appearance none
			padding: 10px 25px
			float right
			color: colorText
			font-family: bodyFontFamily
			border: 2px solid #cecaca;
		}
	}

	div.wpcf7-response-output {
		border: none!important
	}
}