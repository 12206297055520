.select {
	height: 4.5rem
	background: white
	border: 2px solid #cecaca
	position: relative
	line-height: 33px
	padding: 0 1.2rem
	&:after {
		content: ''
		display: block
		position: absolute
		width: 0
		height: 0
		border-style: solid
		border-width: 6px 4px 0 4px
		border-color: colorRed transparent transparent transparent
		top: 50%
		margin-top: -3px
		right: 19px
		pointer-events: none
	}
}

.select select {
	display: block
	width: 100%
	height: 4.5rem
	appearance: none
	opacity: 0
	position: absolute
	top: 0
	right: 0
	bottom: 0
	left: 0
}

.select__label {
	width: 100%
	height: 4.1rem
	line-height: 4.1rem
}
