.breadcrumbs {
	background: #f2f2f2
	line-height: 50px
	height: 50px
	white-space: nowrap;
	position: relative;
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 30px;
		background: linear-gradient(to right, rgba(white, 0%) 0%, #b7b7b7 100%);
		display: none;

		@media(max-width: 767px) {
			display: block;
		}
	}
}

.breadcrumbs .container {
	overflow-x: auto;
}

.breadcrumbs a {
	color: inherit
	text-decoration: none
	&:hover {
		text-decoration: underline
	}
}

.breadcrumbs__delimeter {
	color: #b2b2b2
}
