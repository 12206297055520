.woocommerce fieldset {
	margin: 0
	padding: 0
	border: 0
}
.woocommerce legend {
	@extends .h3
}


.woocommerce-error {
	margin: 0 0 vspace
	padding: 0
	list-style: none
	color: colorBlue
}

label[for="rememberme"] {
	display: block
	margin-bottom: vspace !important
}

.woocommerce-message {
	margin-bottom: vspace
}


.edit-account {
	max-width: 500px
}


.form-row label {
	display: block
	margin-bottom: 3px
}



.checkout_coupon {
	margin-top: vspace
	display: flex
	width: 100%
	max-width: 400px
	align-items: center
	.form-row-first {
		flex: 1 1 auto
	}
	.form-row-last {
		flex: 0 0 auto
		margin-left: 16px
	}
}

.woocommerce-checkout .col-1,
.woocommerce-checkout .col-2 {
	display: block;
	max-width: 700px;
}

.wc_payment_methods {
	list-style: none;
	margin: 0;
	padding: 0;
}



body.page-product-single .woo-main {
	max-width: none;
	padding-left: 0;
	padding-right: 0;

	@media(max-width: 1500px) {
		padding-left 27px
		padding-right 30px
	}

	@media(max-width: 1000px) {
		padding-left 0px
		padding-right 0px
	}
}


.woocommerce-tabs .panel {
	display: none !important;
}


#ship-to-different-address {
	margin: 2em 0;
}


.woocommerce-checkout-payment {
	margin-bottom: vspace;
}

.woocommerce-billing-fields #billing_address_1_field {
	margin-bottom: 5px;
}


.woocommerce-order .woocommerce-customer-details {
	margin-top: 40px;
}

.woocommerce-checkout button {
	margin-top: 40px!important;
}

// Price Warnings
.product-meta .price-warning {
	display: inline-block;
	margin: 20px 0;
	font-size: 16px;
}

.product-item__price .price-warning {
	display: none;
}

.product-item--1 > div:hover .product-item__info .price-warning {
	@media(min-width: 769px) {
		display: inline-block;
		font-size: 16px;
		font-weight: 300;
		text-transform: none;
	}
}

// Cart - shipping options
#shipping_method li {
	list-style: none;

	&:first-of-type {
		margin-bottom: 10px;
	}
}

// checkout message
.wc_payment_method.payment_method_cod label {
	display: block;
	margin: 20px 0px;
	font-size: 22px;
	color: colorRed;
}

.cat-info {
	float: left;
	width: 100%;

	div {
		width: 100%;
		height: 300px;
		margin-top: 30px; 
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	p {
		margin-top: 30px;
	}
}