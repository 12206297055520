.filters-container {
	position: relative;
	border: 2px solid colorLightGrey;
	margin-bottom: 25px;

		@media(max-width: 767px) {
			button {
				margin: 3px auto;
			}
		}

	h2.intro {
		text-align: center;
		font-size: 26px;
		margin-top: 20px;

		@media(max-width: 767px) {
			display: none;}
		}
}

.filters {
	@media (max-width: 767px) {
		position: absolute;
		z-index: 8;
		top: 0;
		left: -20px;
		right: 40px;
		width: 100%;
		margin: 0;
		padding: 0 0 0 20px;
		transform: translate(-100%, 0);
		transition: 0.3s all;
	}
}

.filters--active {
	@media (max-width: 767px) {
		transform: none;
		left: 0px;
		right: 0px;
		padding-left:0px!important;
	}
}

.filters > div {
	background: white
	padding: 18px 15px 0px 15px;
	display: flex
	@media (max-width: 767px) {
		flex-wrap: wrap;
		padding: 20px 0;
	}
}

.filter-group {
	flex: 1 1 (100/6)%
	position 	: 	relative;
	margin-left: 25px
	margin-right: 25px
	@media (max-width: 767px) {
		flex: 0 1 100%;
		&:not(:first-of-type) {
			margin-top: 25px;

				@media(max-width: 767px) {
					margin-top	:	10px; 
				}
		}
	}

	.filer-group__inner {
		position	:	relative;
		height 		:	50px;
		padding		:	10px 0;

		@media(max-width: 1350px) {
			height	:	100px;
		}
		@media(max-width: 767px) {
			margin-bottom	:	0!important; 
		}

		&:first-of-type {
			margin-bottom	:	20px;
		}
	}

	h3 {
		margin 		: 	0;
	}

	.dd, .pseudo-dd {
		position 			: 	absolute;
		overflow 			: 	hidden;
		-ms-overflow-style	:	none;
		max-height 			: 	250px;
		background 			: 	#fff;
		border 				: 	2px solid colorLightGrey;
		padding 			: 	0 15px;
		width 				: 	70%;
		right 				: 	0;
		top					:	0;
		// z-index 			: 	-1;
		z-index 			: 	9;
		// opacity 			: 	0;
		transition 		 	: 	all 0.3s linear;

		@media(max-width: 1350px) {
			width	:	100%;
			top		: 	50px; 
		}

		.dd-trigger {
			position			: 	absolute;
			right 				:	0;
			top					: 	0;
			height 				:	37px
			width				:	30px; 
			cursor 				:	pointer;
			display				: 	flex;
			align-items			: 	center;
			justify-content		: 	center;

			img {
				max-width	:	15px;

				&:last-of-type {
					display: none;
				}
			}
		}

		p {
			margin-bottom	:	0;
			display			: 	inline-block;
			padding			: 	5px 0; 
			color			:	colorLightGrey
		}

		.inputs-selected {
			color	:	colorGreyDark;
		}


		a {
			display			:	inline-block;
			padding			:	0;
			margin-left		:  	5px; 
			cursor 			:	pointer;
			text-decoration	: 	underline;
			color			:	colorLightGrey; 

			&:hover {
				text-decoration	: none;
			}
		}

		ul {
			opacity		:	0;
			max-height	:	0;
			transition	:	all 0.3s;
		}

		&.is-visible {
			overflow 			: 	auto;
			opacity 			: 	1;
			z-index 			:   10;
			transition 		 	: 	all 0.3s;

			ul {
				opacity 	:	1;
				max-height	:	250px; 
				z-index 	: 	150;
			}

			.dd-trigger img {
				&:first-of-type {
					display:none;
				}
				&:last-of-type {
					display:block;
				}
			}
			li {
				&:first-of-type {
					margin-top: 20px;
				}
			}
			.side-by-side {
				@media(min-width:1120px) {
					li {
						&:first-of-type,
						&:nth-of-type(2)  {
							margin-top: 20px;
						}
					}
				}
			}
		}
	}
}

.filters fieldset {
	margin: 0
	padding: 0
	border: 0
}



.filter-checkboxes {
	list-style: none
	margin: 0 -10px
	padding: 0
	font-size: 0
}

.filter-checkboxes li {
	display: inline-block
	width: 100%
	font-size: 1.8rem
	padding: 0 10px
	vertical-align top
	margin-bottom 10px
	
}

.side-by-side li {

	width 	: 	50%;

	@media (max-width: 1120px) {
		width: 100%
	}
}

.filter-checkboxes input[type="checkbox"] {
	@extends .sr-only
}

.filter-checkboxes label {
	position: relative
	display: inline-block
	line-height: 1
	padding-left: 25px
	vertical-align top
	color: colorGreyDark
	cursor: pointer
	&:before {
		content: ''
		display: block
		position: absolute
		top: 1px;
		left: 0;
		width: 15px;
		height: 15px;
		border: 2px solid colorLightGrey
	}

	
}

.filter-checkboxes input[type="checkbox"]:checked + label {
	color: colorRed;

	&:before {
		background: colorRed
		border: 1px solid colorRed
	}

	&::after {
		content: "\f00c";
		position: absolute;
		font-family: "FontAwesome";
		left: 3px;
		top: 4px;
		color: #fff;
		font-size: 9px;
	}
}



.filter-range > div {
	display: inline-block
	color: colorLightGrey
	text-transform: uppercase
	margin-bottom: 6px
	margin-right: 20px
}

.filter-range__min,
.filter-range__max {
	color: colorText
	margin-left: 7px
}

.submit-container {
	margin: auto;
}

.filters__submit {
	display: inline-block;
	width: auto;
	height: auto;
	margin-top: 0px
	margin-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
	padding: 15px;
	// @media (max-width: 991px) {
	// 	padding: 0 10px;
	// 	font-size: 16px;
	// }

	// @media(max-width:1120px) {
	// 	background 	: 	#000;
	// 	margin-top 	: 	-20px;

	// 	&:hover {
	// 		background: #222;	
	// 	}	
	// }
}

.visible-at-1120 {
	display:	none;

	@media(max-width:1120px) {
		display 	: 	block
	}	
}

.filters-title {
	margin-top 	: 	0px;
	margin-left : 	24px;	
	text-transform : uppercase;
}

.filters__submit svg {
	fill: white
	width: 20px
	height: 20px
	margin-right: 12px
	vertical-align: -0.1em
	@media (max-width: 991px) {
		display: none;
	}
}

.filter-range-slider {
	margin-top: 20px;
}

.section {
	&.woo-main {
		padding-top 0px
	}
}
.filters-header {
	appearance: none;
	padding: 0;
	background: none;
	border: 0;
	border-radius: 0;
	text-transform: uppercase;
	text-decoration: underline;
	color: colorRed;
	font-size: 18px;
	font-family: headerFontFamily;
	text-align: center;
	display: block;
	height: 42px;
	line-height: 42px;
	width: 100%;
	margin-bottom: 25px;
	@media (min-width: 767px) {
		display: none;
	}
}

.filter-icon {
	margin-top 0px;

	&:before {
		transform: translate(0, 8px) rotate(45deg)
	}
	&:after {
		transform: translate(0, -8px) rotate(-45deg)
	}
	span {
		opacity: 0
	}
}

.filters-toggle {
	appearance: none;
	border-radius: 0;
	padding: 0;
	position: absolute;
	top: 0;
	right: 0px;
	background: colorRed;
	width: 42px;
	height: 42px;
	svg {
		width: 22px;
		height: 22px;
		display: block;
		margin: 0 auto;
	}
	@media (min-width: 767px) {
		display: none;
	}


	.nav-toggle__icon:before, .nav-toggle__icon:after {
		background-color: #ffffff;
	}
}


body.filters-open {
	position: fixed;
	overflow: hidden;
	.filters {
		transform: translateY(-20%);
		z-index: 11;
		max-height: 70vh;
		overflow: scroll;
		padding-bottom: 80px;
	 }
	 .modal {
		 display: none;
	}
}
