
// .posts {
// 	list-style: none;
// 	padding-top: 30px;
// 	clear: left;
// 	height: auto;
// 	overflow: auto;
// }

.posts {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.post-preview-wrapper {
	margin: 30px 0;
}

.post-section {
	padding: 40px 0;
}

.post-preview {
	position: relative;
	width: 100%;
	background-color: #f2f2f2;
}

.post-preview-wrapper {
    display: flex;
    width: 33.33333%;
    padding: 0px 15px;

    @media(max-width: 768px) {
        width: 50%
    }
    @media(max-width: 568px) {
        width: 100%
		padding: 0px;
    }
}

.post-preview__thumbnail-wrapper {
	position: relative;
	display: block;
	text-decoration: none;
}

.post-preview__thumbnail {
	text-decoration: none;
	position: relative;
}

.post-preview__title {
	margin-bottom: 0;
	
	a {
		text-decoration: none;
	}
}

.post-preview__text {
	padding: 20px;
}

.post-preview__thumbnail {
	height: 200px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	
	@media(max-width:960px) {
		height: 200px;
	}
}

.post-preview__date {
   font-size: 14px;
   margin-top: 0;

}

.post-preview__link {
	margin-top: 20px;
	position: absolute;
	bottom: 30px;
	
	&:hover {
		text-decoration: underline;
	}
}

.post-preview .btn {
    background: #ed1c25;
    color: #fff;
    padding: 15px 25px;
}

.post__featured-image {
	height: 200px;
    background-repeat: no-repeat;
    background-size: cover;

	@media(max-width:960px) {
		height: 400px;
	}
}

.post__header {
	padding: 30px 0;
}

.post__content {
	padding: 30px 0 50px;
}

// .pagination {
// 	margin: 0 auto;
// 	position: relative;

// 	.current,
// 	.page-numbers,
// 	a {
// 		width: 21px;
// 		height: 21px;
// 		display: block;
// 		float: left;
// 		text-decoration: none;
// 		text-align: center;
// 		margin: 0 2px;
// 		padding-top: 4px;
// 		font-size: 12px
// 	}

// 	.current {
// 		color: white;
// 	}

// 	a {
// 		background-color: white;
// 	}
// }

