.pagination {
	background: white;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
	text-align: center;
	position: relative;
	font-size: 18px;
	font-family: headerFontFamily;;
	height: 46px;
	line-height: 46px;
	text-transform: uppercase;
}

.pagination--before {
	margin-bottom: 25px;
}
.pagination--after {
	margin-top: 25px;
	margin-bottom: vspace;
}

.pagination .page-numbers {
	margin: 0 5px;
}

.pagination .next {
	position: absolute;
	top: 0;
	right: 15px;
	margin: 0;
}

.pagination .prev {
	position: absolute;
	top: 0;
	left: 15px;
	margin: 0;
	span {
		@media (max-width: 600px) {
			display: none;
		}
	}
}

.pagination a {
	color: inherit;
	text-decoration: none;
}

.pagination .current {
	color: colorRed;
}

.pagination .page-numbers svg {
	display: inline-block;
	width: 15px;
	height: 15px;
	vertical-align: -1px;
	fill: currentcolor;
}

.pagination .prev svg {
	margin-right: 9px;
}

.pagination .next svg {
	margin-left: 9px;
}
