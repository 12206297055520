input[type="checkbox"] {
	@extends .sr-only
}

.checkbox {
	position: relative
	padding-left: 35px
}

.checkbox:before {
	content: ''
	display: block
	position: absolute
	top: -1px
	left: 0
	width: 25px
	height: 25px
	border: 2px solid #cecaca
}

.checkbox--checked:after {
	content: ''
	display: block
	position: absolute
	top: 6px
	left: 5px
	background: url('../images/checkbox-tick.svg') top left no-repeat
	background-size: contain
	width: 15px
	height: 15px
}

.checkbox--focused:before {
	border-color: colorRed
}
