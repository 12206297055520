.why-nj {
	list-style: none
	padding: 0
	line-height: 1
	font-size: 0
	max-width: 1000px
	margin: 0 auto vspace
	@media (max-width: 920px) {
		text-align: center;
	}
}

.why-nj li {
	font-size: 3rem
	display: inline-block
	vertical-align: top
	width: 50%
	margin: 30px 0 0
	@media (max-width: 920px) {
		width: 100%;
	}
	@media (max-width: 600px) {
		font-size: 2.5rem;
	}
}

.why-nj svg {
	width: 24px
	height: 24px
	fill: white
	margin-right: 10px
}
