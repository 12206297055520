/**
 * Reviews
 */
.woocommerce-tabs {
	@extends .section;
	@extends .section--main;
}

.woocommerce-tabs .tabs {
	display: none;
}

.woocommerce-tabs .panel {
	@extends .container;
}

#tab-additional_information {
	display: none !important;
}
#tab-reviews {
	display: block !important;
}

#comments h2 {
	margin-top: 0;
}

.commentlist {
	list-style: none;
	margin: 0 -10px;
	padding: 0;
	font-size: 0;
}

.comment {
	padding-left: 10px;
	padding-right: 10px;
	display: inline-block;
	vertical-align: top;
	font-size: fontSize;
	width: 50%;
	color: #b3b3b3;
	@media (max-width: 1000px) {
		width: 100%;
	}
}

.comment-inner {
	border: 1px solid colorRed;
	padding: 25px;
}

.comment__meta {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
	flex-wrap: wrap;
}

.comment__author {
	color: #a09f9f;
	margin: 0 20px 0 0;
}

.comment__text p:last-child {
	margin: 0;
}

.comment__rating {
	font-size: 0;
}
.comment__rating div {
	display: inline-block;
	margin-left: 4px;
}
.comment__rating svg {
	width: 16px;
	height: 16px;
	fill: colorRed;
}




.comment-respond .stars a {
	text-indent: -9999px;
	display: inline-block;
	margin-right: 5px;
	width: 24px;
	height: 24px;
	background: url('../images/star-o.svg') top left no-repeat;
	background-size: contain;
}

.comment-respond .stars.selected a {
	background-image: url('../images/star.svg');
}

.comment-respond .stars a.active ~ a {
	background-image: url('../images/star-o.svg');
}


.comment-respond textarea {
	max-width: 700px;
	display: block;
}

.comment-respond input[type="text"] {
	max-width: 700px;
}

.comment-respond label {
	display: block;
}
