.slider__slide {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%
	height: 550px;
	padding: 50px 10px;
	background-size:cover;
	background-repeat: no-repeat;
	background-position: center center;
	text-decoration: none;

	@media (max-width: 1400px) {
		height: 400px; 
	}	

	@media (max-width: 1300px) {
		height: 350px; 
	}

	@media (max-width: 767px) {
		height: 250px; 
	}
}



.slider__slide p {
	position: relative
	z-index 2;
	max-width: 500px;
	margin: 0px;
	font-size: 3.2rem;
	line-height: 3.6rem;
	text-decoration: none;
	text-align: center;
	color: white;
}

.slider img {
	display: block
	width: 100%
}


.slider__nav {
	appearance: none
	background: colorRed
	border: 0
	border-radius: 0
	display: block
	position: absolute
	z-index: 1
	top: 50%
	width: 30px
	height: 80px
	margin-top: -40px
	padding: 0;
	@media (max-width: 767px) {
		width: 24px
		height: 50px
		margin-top: -25px
	}
}

.slider__nav--prev {
	left: 0
}

.slider__nav--next {
	right: 0
}

.slider__nav svg {
	fill: white
	width: 16px
	height: 16px
	display: block
	margin: 0 auto;
	@media (max-width: 767px) {
		width: 12px
		height: 12px
	}
}
