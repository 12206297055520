.social-icons {
	flex: 0 0 100%
	font-size: 1.9rem
	margin-bottom: 1.5rem
}

.social-icons a {
	display: inline-block
	vertical-align: middle
	transition 0.4s ease
	&:not(:first-child) {
		margin-left: 12px
	}
	&:hover svg {
		fill: white
	}
}

.social-icons svg {
	display: block
	width: 1.2em
	height: 1.2em
	fill: colorRed
	transition 0.4s ease
}
