.sale-tag {
	width: 110px;
	height: 150px;
	position: absolute;
	top: 0;
	left: 10px;
	background: url('../images/sale-tag@2x.png') top left no-repeat;
	background-size: 100% 100%;
	color: white;
	font-size: 29px;
	text-transform: uppercase;
	line-height: 1;
	pointer-events: none;
}

.sale-tag strong {
	display: block;
	position: absolute;
	left: 4px;
	bottom: 0;
	width: 97px;
	text-align: center;
}

.sale-tag__ends {
	font-size: 26px;
	font-weight: 300;
	position: relative;
	top: -4px;
}

.sale-tag__date {
	font-size: 19px;
	position: relative;
	top: -13px;
}
