/**
 * Order Summary
 */
.woocommerce-thankyou-order-details {
	list-style: none;
	margin: 0;
	padding: 0;
}

.shop_table.order_details {
	text-align: left;
	border-collapse: collapse;
	table-layout: fixed;
	width: 100%;
	max-width: 600px;
	thead th {
		padding: 0;
		padding-bottom: 10px;
		border-bottom: 1px solid colorRed;
	}
	tbody td {
		padding: 20px 0;
		border-bottom: 1px solid rgba(#bdbdbd, 0.3);
	}
	tfoot tr:first-child td,
	tfoot tr:first-child th {
		padding-top: 20px;
	}
	thead th:not(:first-child) {
		padding-left: 30px;
		text-align: right;
	}
	tbody td:not(:first-child),
	tfoot td:not(:first-child) {
		text-align: right;
		padding-left: 30px;
	}
}

.shop_table.customer_details {
	text-align: left;
	td {
		padding-left: 30px;
	}
}

.col2-set.addresses {
	.col-1,
	.col-2 {
		width: 300px !important;
	}
}
