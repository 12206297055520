.cta {
	background-size: cover;
	background-position: center center;
	color: white;
}

.cta .container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.cta__text {
	flex: 0 1 500px;
	margin: 0 30px vspace 0;
	font-size: 40px;
	font-family: bodyFontFamily
	text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.6);
	line-height: 1.1;
	@media (max-width: 767px) {
		font-size: 30px;
	}
}

.cta__btn {
	flex: 0 0 auto;
	margin-bottom: vspace;
	display: inline-block;
	height: 52px;
	line-height: 48px;
	text-decoration: none;
	background: white;
	border: 3px solid colorRed;
	text-transform: uppercase;
	color: colorText;
	box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.6);
	padding: 0 20px;
	font-family: headerFontFamily;
	&:hover {
		background: colorRed;
		color: white;
	}
}
