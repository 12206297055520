.footer {
	padding-top: 70px;
	padding-bottom: 70px;
	background: colorGreyDark
	color: #b2b2b2

	@media(max-width: 568px) {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}

.footer-inner {
	display: flex
	flex-wrap: wrap
	margin-left: -20px
	margin-right: -20px
}

.footer-inner > * {
	margin-left: 20px
	margin-right: 20px
	flex: 1 1 auto
	margin-bottom: vspace
}

.footer .h2 {
	color: white
	margin-bottom: 2.5rem
}



.footer__nav ul {
	list-style: none
	margin: 0
	padding: 0
}

.footer__nav li:not(:first-child) {
	margin-top: 6px
}

.footer__nav a, .footer_creditation a {
	color: inherit
	text-decoration: none
	&:hover {
		color: white
	}
}

.footer_creditation {
	text-align center
}


.footer__sales {
	order: -1
}
.footer__sales a {
	&:hover {
		text-decoration: none;
	}
}

.footer__email {

	text-decoration none
	transition 0.4s ease

	&:hover {
		transition 0.4s ease
		color white
	}
	@media (max-width: 400px) {
		font-size: 0.8em
	}
}

.footer__nav {
	.sub-menu {
		display none
	}

	&.trim {
		@media(max-width 568px) {
			width 100%
		}
	}

	&.empty {
		@media(max-width 568px) {
			display none
		}
	}
}


.social-icons--footer {
		margin-bottom: 2.5rem!important
}