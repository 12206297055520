.home-tabs {
	margin: 50px -20px -1px
	font-size: 0
}

.home-tabs > div {
	padding: 0 20px
	font-size: 2.1rem
	text-transform: uppercase
	text-align: center
	width: 50%
	display: inline-block
	vertical-align: top
	font-family: headerFontFamily;
	@media (max-width: 600px) {
		padding: 0
		font-size: 1.6rem
	}
}

.home-tab {
	display: block
	height: 66px
	line-height: 66px
	text-decoration: none
	color: white
	background: colorGreyDark
	@media (max-width: 600px) {
		height: 50px
		line-height: 50px
	}
}

.home-tab--active {
	background: colorRed
	border: 1px solid colorGreyDark
	border-bottom: 0
}





.home-tab-content {
	margin-bottom: vspace
	display: none
}

.home-tab-content--active {
	display: block
}
