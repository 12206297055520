.product-list {
	list-style: none
	margin: 0 -5px
	padding: 0
	font-size: 0
}

.product-item {
	font-size: fontSize
	display: inline-block
	vertical-align: top
	position: relative
	font-size: 1.8rem
	&:after {
		content: ''
		display: block
		padding-top: 100%
	}
	&:before {
		content: ''
		display: block
		position: absolute
		top: 5px
		right: 5px
		bottom: 5px
		left: 5px
		box-shadow: 0 0 5px rgba(black, 0.4)
	}
}

.product-item > div {
	position: absolute
	overflow: hidden
	top: 5px
	right: 5px
	bottom: 5px
	left: 5px
}

.product-item--sizer {
	width: 25%
}

.product-item--1 {
	width: 25%
	&:after {
		padding-top: 75%
	}
	@media (max-width: 1199px) {
		width: 50%
	}
	@media (max-width: 659px) {
		width: 100%
	}
}

.product-item--2 {
	width: 25%
	&:after {
		padding-top: 150%
		@media (max-width: 659px) {
			padding-top: 75%
		}
	}
	@media (max-width: 1199px) {
		width: 50%
	}
	@media (max-width: 659px) {
		width: 100%
	}
}

.product-item--3 {
	width: 50%
	&:after {
		padding-top: 75%
	}
	@media (max-width: 659px) {
		width: 100%
	}
}

.product-item__info {
	position: absolute
	bottom: 0
	left: 0
	width: 100%
	background: colorRed
	color: white
	display: flex
	align-items: center
	padding: 15px
	text-transform: uppercase
	line-height: 1
	font-family: headerFontFamily;
}

.product-item__name {
	margin: 0
	flex: 1 1 auto
	padding-right: 20px
	white-space: nowrap
	overflow: hidden
	text-overflow: ellipsis
	font-size: 1em
}

.product-item__price {
	flex: 0 0 auto
	ins {
		text-decoration: none;
	}
}



.product-item__actions {
	position: absolute
	top: 0
	left: 100%
	display: flex
	flex-direction: column
	white-space: nowrap
}

.product-item__actions a {
	display: block
	color: white
	font-size: 0.888em
	text-decoration: none
	padding: 5px 8px
	transform: translate(-36px, 0)
	transition: transform 0.3s
}

a.product-item__action:hover,
a.product-item__action--out {
	transform: translate(-100%, 0)
}

a.product-item__action--out:not(.product-item__action--done) {
	opacity: 0.5;
}

.product-item__action--add {
	background: colorBlue
}

.product-item__action--view {
	background: colorGreyDark
}

.product-item__actions svg {
	width: 20px
	height: 20px
	fill: white
	display: inline-block
	vertical-align: middle
	margin-right: 12px
}

.product-item__actions span {
	display: inline-block
	vertical-align: middle
}


.product-item .added_to_cart {
	display: none
}


.product-item__rrp {
	width: 0
	height: 0
	overflow: hidden
}


.product-item__link {
	position: absolute
	top: 0
	right: 0
	bottom: 0
	left: 0
	background-position: center center;
	background-size: cover;
	background-color: colorRed;
}

.product-item__cat {
	display: none;
}

@keyframes fade {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
.product-item > div:hover {

	@media(min-width: 769px) {
		.product-item__info {
			animation fade
			animation-duration: 1s
			top: 0
			flex-direction: column
			justify-content: center
			font-size: 2.5em
			text-align: center
		}

		.product-item__name {
			white-space: normal
			flex: 0 0 auto
			padding: 0
			margin: 0 0 30px
		}

		.product-item__rrp {
			font-size: 0.4em
			font-weight: fontWeight
			margin-top: 6px
		}

		.product-item__price del {
			display: block;
			font-size: 60%;
		}

		.product-item__cat {
			display: block;
			font-size: 20px;
			font-weight: 300;
			flex: 0 0 auto;
			margin-bottom: 30px;
			text-transform: none;
		}
	}
}

.product-item--1 > div:hover {
		@media(min-width: 769px) {
			.product-item__info {
				font-size: 1.5em;
			}
		}
}



/**
 * Category grid
 */
.product-item--cat .product-item__name {
	margin: 0 !important
	padding: 0
	text-align: center
}
