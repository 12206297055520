body.modal-open {
	position: fixed;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.modal-container {
	position: fixed
	// z-index: 10
	z-index: 101
	top: 0
	right: 0
	bottom: 0
	left: 0
	background: rgba(black, 0.8)
	padding: 20px
	display: flex
	flex-direction: column
	overflow: auto
	-webkit-overflow-scrolling: touch
	@media (max-width: 600px) {
		padding: 0;
	}
}

.modal-container:not(.modal-container--active) {
	display: none
}

.modal {
	background: white
	width: 100%
	max-width: 1400px
	flex: 0 0 auto
	margin: auto
	padding: 55px
	position: relative;
	@media (max-width: 600px) {
		padding: 55px 20px 20px 20px;
	}
}

.modal__close {
	position: absolute;
	top: 20px;
	right: 20px;
	appearance: none;
	padding: 0;
	background: none;
	border: 0;
	border-radius: 0;
	width: 16px;
	height: 16px;
	display: block;
	svg {
		fill: colorRed;
		width: 16px;
		height: 16px;
		display: block;
	}
}
