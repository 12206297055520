.shop_table.cart {
	table-layout: fixed
	width: 100%
	text-align: right
}

.shop_table.cart a {
	color: inherit
	text-decoration: none
}

.shop_table.cart th {
	font-size: 2.1rem
	text-transform: uppercase
	border-bottom: 1px solid colorRed
	padding-bottom: 10px
}

.cart_item td {
	padding-top: 20px
	padding-bottom: 20px
	border-bottom: 1px solid rgba(#bdbdbd, 0.3)
}

.shop_table.cart .product-name,
.shop_table.cart .product-remove {
	text-align: left
}

.shop_table.cart .product-remove {
	width: 36px
	a {
		display: block
		width: 12px
		height: 12px
		background: url('../images/remove.svg') top left no-repeat
		background-size: 100% 100%
		text-indent: -9999px
	}
}

.shop_table.cart .product-thumbnail {
	width: 0
}

.shop_table.cart .product-subtotal {
	font-family: headerFontFamily
}


.shop_table.cart .quantity {
	max-width: 70px
	display: inline-block
}

.shop_table.cart .quantity__label {
	@extends .sr-only
}


.shop_table.cart .actions {
	padding-top: 30px
}

.shop_table.cart .coupon {
	max-width: 400px
	width: 100%
	float: left
	display: flex
	label {
		@extends .sr-only
	}
	input[type="text"] {
		flex: 0 1 250px;
	}
	.button {
		margin-left: 16px
		flex: 0 0 auto;
	}
	@media (max-width: 650px) {
		max-width: none
		margin-bottom: 30px
	}
}

.shop_table.cart input[type="submit"] {
	float: right
	@media (max-width: 650px) {
		float: none
	}
}




.cart_totals {
	max-width: 400px
	margin-left: auto
	margin-top: 60px
	margin-bottom: vspace
}

.cart_totals h2 {
	@extends .h2
	text-transform: uppercase
	border-bottom: 1px solid colorRed
	margin: 0
	padding-bottom: 10px
}

.cart_totals .shop_table {
	text-align: left
	table-layout: fixed
	width: 100%
	font-family: headerFontFamily
	text-transform: uppercase
	td {
		text-align: right
	}
	th,
	td {
		line-height: 1;
		padding: 24px 0
		border-bottom: 1px solid rgba(#bdbdbd, 0.3)
		vertical-align: top;
	}
	p {
		margin: 0
	}
}


.wc-proceed-to-checkout {
	text-align: right
	margin-top: 40px
}


.cart_item .variation {
	margin: 0;
	padding: 0;
	font-size: 13px;
	dt, dd {
		margin: 0;
		padding: 0;
		display: inline;
	}
	dd:after {
		content: '\A';
		white-space: pre;
	}
	p {
		margin: 0;
		display:inline;
	}
}


/**
 * Mobile
 */
 @media (max-width: 700px) {

	.shop_table.cart {
		thead, tbody, tr, th, td {
			display: block
			padding: 0
			text-align: left
		}
		tr {
			position: relative
		}
		th.product-remove,
		th.product-thumbnail,
		th.product-price,
		th.product-subtotal {
			display: none
		}
		th.product-quantity {
			position: absolute
			top: 0
			right: 0
		}
		td {
			border: 0
		}
		.cart_item {
			border-bottom: 1px solid rgba(#bdbdbd, 0.3)
			padding: 20px 90px 20px 30px
		}
		.cart_item .variation {
			margin-bottom: 12px;
		}
		td.product-remove {
			position: absolute
			top: 25px
			left: 0
		}
		td.product-quantity {
			position: absolute
			top: 20px
			right: 0
		}
		td.product-price:before {
			content: 'Price: '
		}
		td.product-subtotal:before {
			content: 'Subtotal: '
		}
	}

}

.checkout-button {
	width 100%
	display block
	font-size 18px
	text-align center
}

.shipping-calculator-form {
	p {
		&:nth-last-of-type(1){
			margin-top 20px

			button {
				background: #31b7be;
				color: white;
				padding: 15px 25px;

				@media(max-width 568px) {
					font-size 14px
				}
			}
		}
	}
}

.calc_shipping_country {
	.select__label {
		text-align: left
		font-size 12px;
	}
}
.shipping-calculator-form {
	.select__label, .input-text {
		text-align: left
		font-size 12px;
	}
}

.shipping-calculator-button {
	padding 10px 0px
	display block
}

#calc_shipping_country_field {
	.select {
		display none
	}
}


/*#calc_shipping_country, #calc_shipping_postcode_field {
	display none
}
